/* eslint-disable @typescript-eslint/camelcase */

export const en = {
  hanabi: "Hanabi",
  welcome: "Welcome",
  newGame: "New game",
  tagline: "Play Hanabi online with friends!",
  subTagline: "Cooperative multiplayer card game available on phone, tablet & desktop",
  createRoom: "Create a room",
  joinRoom: "Join a room",
  rejoinGame: "Rejoin game",
  whatsHanabi: "What's Hanabi ?",
  invitationByPlayers: "{{playersNames}} are inviting you to a game of Hanabi 🎉",
  invitationByPlayer: "{{playerName}} is inviting you to a game of Hanabi 🎉", // necessary in French
  invitationNoPlayers: "You're being invited to a game of Hanabi 🎉",
  gameFull: "Everybody's here!",
  gameNotFull: "{{joined}} / {{playersCount}} joined already",
  gameNotFullPlural: "{{joined}} / {{playersCount}} joined already", // necessary in French
  startGame: "Start game",
  addAi: "+ add AI",
  lobby: "Lobby",
  choosePlayerName: "Choose your player name",
  join: "Join",
  autoplay: "Autoplay",
  shareGame: "Share this game",
  copy: "Copy",
  waitForOthers: "Wait for others, or ",
  gameStarted: "Game started!",
  gameStarts: "Game starts!",
  discardPile: "discard ({{ discardLength }})",
  preventLossContent: "You fucked up · Keep going?",
  rewind: "🕑 Rewind",
  backToGame: "Back to game",
  theirTurn: "It's {{currentPlayerName}} turn!",
  go: "Go!",
  estimatedMaxScore: "Estimated max score for this shuffle: {{reachableScore}}.",
  keepPracticing: "Keep practicing",
  congrats: "You did great!",
  hideStats: "Hide Stats",
  showStats: "Show Stats",
  gameOver: "The game is over! • Your score is {{playedCardsLength}} 🎉",
  score: "Hanabi • Score: {{score}} / {{maxPossibleScore}}",
  turnLeftDisclaimer: "· 1 turn left",
  turnsLeftDisclaimer: "· {{actionsLeft}} turns left",
  cardLeft: "{{pileLength}} left",
  tokens: "tokens",
  deck: "deck",
  loading: "Loading...",
  menu: "Menu",
  tutorial: "Tutorial",
  rules: "Rules",
  yourTurn: "Your turn",
  youWillStart: "You'll start first",
  hide: "Hide",
  reveal: "Reveal",
  cardSelected: "Card {{position}} selected",
  eightTokens: "8 tokens",
  cannotDiscard: "You cannot discard",
  noTokens: "No tokens left to hint",
  selectVignette: "Select either a color or number to hint",
  hint: "Hint",
  negativeHintColor: "You have no {{ color }} cards",
  negativeHintNumber: "You have no {{ number }}s",
  positiveHintColor: "Your card {{ position }} is {{ color }}",
  positiveHintNumber: "Your card {{ position }} is a {{ number }}",
  positiveHintNumberSequence: "Your card {{ position }} is a {{ number }} or higher",
  positiveHintColorPlural: "Your cards {{ positions }} are {{ color }}",
  positiveHintNumberPlural: "Your cards {{ positions }} are {{ number }}s",
  positiveHintNumberSequencePlural: "Your cards {{ positions }} are {{ number }}s or higher",
  you: "You",
  hinted: "Hinted",
  played: "Played",
  discarded: "Discarded",
  rollback: "Roll back",
  rollbackDisclaimer: "You're about to roll back the last action!",
  cannotRollback: "You cannot rollback the game yet",
  abort: "Abort",
  introTutorial: "Tutorial",
  introContent: "Let's learn how to play!",
  playedCardsTutorial: "Played Cards",
  playedCardsTutorialContent:
    "This will nest the cards that have been played.\nCollectively, reach 5 on each color to win the game.",
  discardTutorial: "Discard",
  discardTutorialContent:
    "Here you will see the cards\nyou and your team discarded.\nAvoid discarding the ones\nyou need to finish the game.",
  yourGameTutorial: "Your game",
  yourGameTutorialContent:
    "These are your cards.\nYou can't see them, but other players can and will give you hints about them.",
  teammatesTutorial: "Teammates",
  teammatesTutorialContent:
    "These are your teammates.\nLike you, they can't see their cards.\nGive them hints to help them play or discard cards.",
  hintTokensTutorial: "Hint tokens",
  hintTokensTutorialContent:
    "Some actions have a cost.\n\n- Giving a hint costs 1 hint token.\n- Discarding a card grants 1 hint token.\n- Playing a 5 gives 1 hint token as a bonus.",
  strikeTokensTutorial: "Strike tokens",
  strikeTokensTutorialContent:
    "Playing a wrong card will discard it and cost you 1 strike token.\nReaching 3 strike tokens will instantly lose the game.",
  yourTurnTutorial: "It's your turn",
  yourTurnTutorialContent:
    "You have 3 options:\n\n- Tap your game to play a card...\n- ... or discard it\n- Tap one of your teammates games to give them a hint.",
  skip: "✕ Skip",
  offline: "You are offline !",
  apologies: "We're sorry, something went wrong 😕",
  errorWillInvestigate:
    "We'll investigate as soon as possible. Don't hesitate to <1>reach out</1> to let us know what happened.",
  earlyDevelopment: "The game is still in early development and we'd love your feedback.",
  publicRepo: "We also have a <1>public Github repository</1>if you'd like to contribute or report a bug.",
  backMenu: "Back to menu",
  landingImageAlt: "Hanabi cards game online logo",
  classicVariant: "Classic",
  orangeVariant: "Orange",
  multicolorVariant: "Multicolor",
  rainbowVariant: "Rainbow",
  sequenceVariant: "Sequence",
  classicVariantDescription: "A classic game of Hanabi with 5 colors",
  multicolorVariantDescription: "A 6th suit is added with only one card of each",
  rainbowVariantDescription: "A 6th suit is added that matches every color",
  orangeVariantDescription: "A 6th classic suit is added",
  sequenceVariantDescription: "Number hints also target higher value cards",
  showDirectHints: "Show direct hints",
  hideDirectHints: "Do not show hints",
  botSpeed: "Bots speed",
  normalGameExplanation: "You will be able to play online by sharing the game link to your friends.",
  passandplayExplanation:
    "In this pass-and-play mode, you can play offline with multiple players that are physically in the same room by passing the device to each player on their turn",
  private: "Private",
  hints: "Hints",
  allowRollback: "Allow rollback",
  preventLoss: "Prevent loss",
  passandplay: "Pass & Play",
  passandplaySubtext: "Physically pass the device to each player on their turn",
  privateSubtext: "Your game won't be visible in the 'Join Room' section",
  advancedOptions: "Advanced options",
  players: "Players",
  mode: "Mode",
  seed: "Seed",
  objective: "Objective",
  rulesIntro:
    "Hanabi is a card game created by Antoine Bauza. It's cooperative, which means that players are not against each other but assemble to reach a common goal. They incarn here distracted pyrotechnists who - byinattention - mixed their powder, wicks and rockets for a large fireworks display. The show will begin soon and the situation is a bit chaotic. They will need to help each other to prevent the show turning to disaster.",
  rulesGoal:
    "The goal of the pyrotechnics team is to build 5 fireworks, one of each color (white, red, blue, yellow, green) by combining increasing value cards (1,2,3,4,5) of the same color.",
  rulesSetupTitle: "Setup",
  rulesSetup:
    "The app sets up everything for you, which is handy 😉. At the beginning of a game, you will have 8 blue tokens - your hints - and 3 red tokens - your strike tokens.\n\nThe deck is composed of 50 cards, 10 of each color <1>with numbers 1, 1, 1, 2, 2, 3, 3, 4, 4, 5</1>.\n· In a 2 or 3 player game, each player will be dealt 5 cards\n· In a 4 or 5 player game, each player will be dealt 4 cards.\n\nAs you will see, players are not allowed to look at their own cards!",
  rulesGameTitle: "Playing the game",
  rulesGame:
    "On each player's turn, they take one (and only one) of the three following actions. You are not allowed to pass.\n\n1. Give information to another player.\n2. Discard a card.\n3. Play a card\n\nPlayers are not allowed to give hints or suggestions on other player's turns!",
  rulesHintTitle: "1. Give information",
  rulesHint:
    "When you give information, it will remove a blue token. Note: If you have no more blue tokens, you cannot choose to give information and must pick a different action.\n\nYou then give information to a fellow player about the cards in that player's hand by clicking on it. You can tell the player either about one (and only one) color, or one (and only one) value of card.",
  rulesDiscardTitle: "2. Discard a card",
  rulesDiscard:
    "Discarding a card returns a blue token. You discard a card from your hand by tapping it. You then draw a new card from the deck and it will be added to your hand. \nNote: If you have all 8 blue tokens, you cannot discard cards and must pick a different action. \nYou can consult discarded cards at anytime.",
  rulesPlayTitle: "3. Play a card",
  rulesPlay:
    "At your turn, to play a card, take a card from your hand and play it.\nOne of two things happen:\n· If the card begins or adds to a firework, it will be added to that firework pile\n· If the card does not add to a firework, it will be discarded the card and add a red strike token\nThen you will draw a replacement card from the deck.\n\nWhen a player finishes a firework by playing a value 5 card on it, it will return one blue token to the lid of the box as a bonus. If all the blue tokens are in the box lid, you do not get the bonus.",
  rulesEndTitle: "End of the game",
  rulesEnd:
    "Hanabi can end in three ways:\n· If you get the third red token, you lose the game as the display goes up in flames!\n· If the team completes all five colors of firework with a value of 5, the team makes a spectacular victorydisplay and obtains the maximum score of 25 points - 30 with multicolor option!\n· If a player draws the last card from deck, the game is almost over. Each player gets one more turn,including the player who drew the last card. Players cannot draw more cards during these final turns.\n\nThe players then score their performance based on the fireworks they assembled.",
  rulesExtensionTitle: "Extension",
  rulesExtensionMulticolor:
    "🎨 The multicolor variant adds up a 6th pile and a 6th set of cards with only one item of each card: 1, 2, 3, 4, 5. These fireworks are therefore more scarce and you will have to be cautious not to discard them. Rules are the same and you have to complete all siw piles to reach a maximum score of 30 points.",
  rulesExtensionRainbow:
    "🌈 The rainbow extension also adds a 6th pile with the same values that the 5 other piles (three 1s, 2 items of cards 2/3/4 and one 5). The particularity is that rainbow cards are of all colors. Every time you give a hint on colors, they will be touched.",
  rulesUsTitle: "Who are we?",
  rulesUs:
    "We are a group of board-game addicts. Locked down in 2020, we were willing to find an alternative to play together remotely.\nSince we both have fun coding and playing Hanabi, we decided to create an online mobile-friendly version of the game.\n\n👉 Like us, please buy the <2>physical version</2> to support its creator if you like this game!",
  rulesBuy:
    "Don't hesitate to <1>contact us</1> if you have any question or suggestion. We also have a <3>public Github repository</3> if you'd like to contribute.",
  noRoom: "No available room",
  faster: "Faster",
  fast: "Fast",
  slow: "Slow",
  discard: "Discard",
  play: "Play",
  green: "green",
  red: "red",
  yellow: "yellow",
  blue: "blue",
  white: "white",
  multicolor: "multicolor",
  orange: "orange",
  green_plural: "green",
  red_plural: "red",
  yellow_plural: "yellow",
  blue_plural: "blue",
  white_plural: "white",
  multicolor_plural: "multicolor",
  orange_plural: "orange",
  summary: "Summary",
  summarySubtitle: "Our Hanabi game",
  gameCompleted: "Game completed in {{ gameDuration }}",
  result: "Our result",
  evolution: "Evolution",
  evolutionSubtext:
    "Follow the game history! Each players’s card are displayed whether they were playable, discardable or dangerous (a card that will lower your max possible score if you discard it, for ex a multicolor or a 5).",
  playerActions: "Average actions per player",
  playHints: "Average plays per hint: <1>{{playsPerHint}}</1>",
  playerHints: "Who gave the most hints?",
  tryOutTitle: "Try it out!",
  tryOutButton: "Try this shuffle",
  tryOutAlternative: "You can also play with other setups and meet our AI on <1>hanabi.cards</1>",
  back: "Back",
  statsPlayable: "playable",
  statsDiscardable: "discardable",
  statsDangerous: "dangerous",
  statsTurnCard: "That turn, the card was",
  statsTurnAction: "Player action on that turn",
  showInsights: "Show insights",
  showCards: "Show cards",
  started: "started",
  youGaveHintTurn: "You hinted <1></1> about their <3></3>",
  youDiscardedTurn: "You discarded <1></1>",
  youPlayedTurn: "You played <1></1>",
  somebodyHintedYouTurn: "<0></0> hinted you about <2></2>",
  somebodyHintedSomebodyTurn: "<0></0> hinted <2></2> about <4></4>",
  somebodyDiscardedTurn: "<0></0> discarded <2></2>",
  somebodyPlayedTurn: "<0></0> played <2></2>",
  whatYouDrewTurn: "and drew <1></1>",
  whatTheyDrewTurn: "and drew <1></1>",
  selectLanguage: "Select a language",
  creatingGame: "Creating game...",
  partySetup: "Players: {{players}} · Mode: {{variant}} · Shuffle #{{shuffle}}",
  sendMessagePlaceholder: "Send a message to your teammates",
  sendMessage: "Send",
  message: "<0></0>: <1></1>",
  contributeLanguage: "Contribute!",
};
