/* eslint-disable @typescript-eslint/camelcase */

export const fr = {
  hanabi: "Hanabi",
  welcome: "Bienvenue",
  newGame: "Nouvelle partie",
  tagline: "Jouez au jeu Hanabi en ligne avec vos amis !",
  subTagline: "Jeu de cartes multijoueur et coopératif, disponible sur téléphones, tablettes & ordinateurs",
  createRoom: "Nouvelle partie",
  joinRoom: "Rejoindre une partie",
  rejoinGame: "Revenir à la partie",
  whatsHanabi: "Hanabi, c'est quoi ?",
  invitationByPlayers: "{{playersNames}} t'invitent à jouer à Hanabi 🎉",
  invitationByPlayer: "{{playerName}} t'invite à jouer à Hanabi 🎉",
  invitationNoPlayers: "Tu as reçu une invitation pour jouer à Hanabi 🎉",
  gameFull: "Tout le monde est là !",
  gameNotFull: "{{joined}} joueur sur {{playersCount}} a rejoint.",
  gameNotFullPlural: "{{joined}} joueurs sur {{playersCount}} ont rejoint.",
  startGame: "Démarrer le jeu",
  addAi: "+ ajouter une IA",
  lobby: "Lobby",
  choosePlayerName: "Choisis ton nom de joueur",
  join: "Rejoindre",
  autoplay: "Autoplay",
  shareGame: "Partager cette partie",
  copy: "Copier",
  waitForOthers: "Tu peux attendre les autres ou ",
  gameStarted: "Début de la partie",
  gameStarts: "Début de la partie",
  discardPile: "défausse ({{ discardLength }})",
  preventLossContent: "Ton action fait perdre un point · Continuer ?",
  rewind: "Remonter le 🕑",
  backToGame: "Retour au jeu",
  theirTurn: "C'est à {{currentPlayerName}}",
  go: "Go !",
  estimatedMaxScore: "Score max estimé pour cette série : {{reachableScore}}.",
  keepPracticing: "Pas mal !",
  congrats: "Incroyable !",
  hideStats: "Voir les cartes",
  showStats: "Voir les stats",
  gameOver: "Game over ! • Votre score est de {{playedCardsLength}} 🎉",
  score: "Hanabi • Score : {{score}} / {{maxPossibleScore}}",
  turnLeftDisclaimer: "· 1 tour restant",
  turnsLeftDisclaimer: "· {{actionsLeft}} tours restants",
  cardLeft: "{{pileLength}} restant",
  tokens: "jetons",
  deck: "pioche",
  loading: "Chargement...",
  menu: "Menu",
  tutorial: "Tutoriel",
  rules: "Règles",
  yourTurn: "C'est à toi !",
  youWillStart: "Tu commenceras",
  hide: "Cacher",
  reveal: "Révéler",
  cardSelected: "Carte {{position}} sélectionnée",
  eightTokens: "8 jetons",
  cannotDiscard: "Tu ne peux pas défausser",
  noTokens: "Plus de jeton pour donner un indice",
  selectVignette: "Sélectionne une couleur ou une valeur pour donner un indice",
  hint: "Annoncer",
  negativeHintColor: "Tu n'as pas de {{ color }}",
  negativeHintNumber: "Tu n'as pas de {{ number }}",
  positiveHintColor: "Ta carte {{ position }} est {{ color }}",
  positiveHintNumber: "Ta carte {{ position }} est un {{ number }}",
  positiveHintNumberSequence: "Ta carte {{ position }} est un {{ number }} ou plus",
  positiveHintColorPlural: "Tes cartes {{ positions }} sont {{ color }}",
  positiveHintNumberPlural: "Tes cartes {{ positions }} sont des {{ number }}",
  positiveHintNumberSequencePlural: "Tes cartes {{ positions }} sont des {{ number }} ou plus",
  you: "Tu",
  hinted: "Indices",
  played: "Cartes jouées",
  discarded: "Défausses",
  rollback: "Révoquer",
  rollbackDisclaimer: "Tu vas révoquer la dernière action.",
  cannotRollback: "Tu ne peux pas encore révoquer la dernière action.",
  abort: "Retour au jeu",
  introTutorial: "Tutoriel",
  introContent: "Apprenons ensemble à jouer !",
  playedCardsTutorial: "Cartes jouées",
  playedCardsTutorialContent:
    "Ceci est l'espace des piles de cartes jouées.\nCollectivement, atteignez 5 sur chaque couleur pour gagner.",
  discardTutorial: "Défausse",
  discardTutorialContent:
    "Les cartes défaussées apparaîtront ici.\nÉvitez de défausser les cartes qui vous permettront de finir des piles.",
  yourGameTutorial: "Ton jeu",
  yourGameTutorialContent:
    "Voilà tes cartes.\n Tu ne les vois pas mais les autres joueurs oui.\nIls te donneront des indices sur tes cartes.",
  teammatesTutorial: "Partenaires",
  teammatesTutorialContent:
    "Tes partenaires, comme toi, ne voient pas leur jeu.\nDonne-leur des indices pour les aider\nà savoir s'ils peuvent jouer ou défausser.",
  hintTokensTutorial: "Jetons bleus",
  hintTokensTutorialContent:
    "Certaines actions ont un coût.\n- Donner un indice coûte 1 jeton bleu\n- Défausser une carte récupère 1 jeton bleu\n- Jouer un 5 donne un jeton bleu bonus",
  strikeTokensTutorial: "Jetons rouges",
  strikeTokensTutorialContent:
    "Jouer une mauvaise carte la défaussera et vous coûtera un jeton rouge de pénalité.\nAu troisième jeton rouge, vous perdez instantanément la partie.",
  yourTurnTutorial: "C'est ton tour !",
  yourTurnTutorialContent:
    "Tu as 3 options\n- Sélectionne ton jeu pour jouer une carte\n- ... ou la défausser\n- Clique sur un partenaire pour lui donner un indice",
  skip: "✕ Passer",
  offline: "Tu es hors ligne",
  apologies: "Désolés, il y a eu un souci 😕",
  errorWillInvestigate:
    "Nous allons jeter un œil sous peu. N'hésitez pas à <1>nous contacter</1> pour nous donner des pistes sur l'incident.",
  earlyDevelopment: "Le jeu est encore en développement et nous serions heureux de récolter vos avis.",
  publicRepo: "Nous avons aussi un <1>repo public sur Github</1> si vous voulez contribuer ou créer une issue.",
  backMenu: "Retour au menu",
  landingImageAlt: "Hanabi.cards Hanabi jeu de cartes en ligne",
  classicVariant: "Classique",
  multicolorVariant: "Multicolore",
  rainbowVariant: "Arc-en-ciel",
  orangeVariant: "Orange",
  sequenceVariant: "Séquences",
  classicVariantDescription: "Le jeu traditionnel d'Hanabi sans extension",
  multicolorVariantDescription: "Une sixième pile est ajoutée avec seulement un exemplaire de chaque valeur",
  rainbowVariantDescription:
    "Une sixième pile est ajoutée dont les cartes sont touchées par tous les indices de couleur",
  orangeVariantDescription: "Une sixième pile classique est ajoutée",
  sequenceVariantDescription: "Les indices de nombres ciblent aussi toutes les cartes supérieures",
  showDirectHints: "Montrer les déductions",
  hideDirectHints: "Cacher les déductions",
  botSpeed: "Vitesse des bots",
  normalGameExplanation: "Tu pourras partager le lien de la partie aux autres joueurs.",
  passandplayExplanation:
    "Dans ce mode 'Pass and play', tu pourras jouer hors ligne avec des joueurs se situant dans la même pièce que toi en se passant un seul appareil",
  private: "Mode privé",
  hints: "Déductions",
  allowRollback: "Permettre de révoquer la dernière action",
  preventLoss: "Avertir d'une action malencontreuse",
  passandplay: "Pass & Play",
  passandplaySubtext: "Si vous êtes dans la même pièce et souhaitez jouer avec un seul appareil",
  privateSubtext: "Ta partie n'apparaîtra pas dans la section 'Rejoindre une partie'",
  advancedOptions: "Options avancées",
  players: "Joueurs",
  mode: "Mode",
  seed: "Seed",
  objective: "Objectif",
  rulesIntro:
    "Hanabi est un jeu de cartes créé par Antoine Bauza. C'est un jeu coopératif : les participants ne jouent pas les uns contre les autres mais coopère pour gagner le jeu.\nIls incarnent dans Hanabi des pyrotechniciens qui ont, par inattention, mélangé leurs poudres, mèches et fusées et tubes avant le grand feu d'artifice tant attendu. Le spectacle s'apprête à commencer et la situation est quelque peu cahotique. Ils doivent donc s'aider pour éviter que le spectacle ne tourne au désastre.",
  rulesGoal:
    "L'objectif des pyrotechniciens est de construire 5 piles de feux d'artifice, une de chaque couleur (blanc, rouge, bleu, jaune, vert) en empilant, de façon croissante, les chiffres des cartes (1, 2, 3, 4, 5).",
  rulesSetupTitle: "Mise en place",
  rulesSetup:
    "L'application hanabi.cards vous installe le jeu, ce qui est assez pratique 😉. Au début du jeu, vous disposez de 8 jetons bleus - vos indices - et de 3 jetons rouges - vos pénalités.\nLa pioche est composée de 50 cartes, 10 de chaque couleur (dont les nombres associés sont 1, 1, 1, 2, 2, 3, 3, 4, 4, 5).\n\nDans une partie à 2 ou 3 joueurs, 5 cartes sont distribuées à chaque joueur.\nDans une partie à 4 ou 5 joueurs, 4 cartes sont distribuées.\n\nComme vous allez le voir, la particularité du jeu réside dans le fait que vous ne voyez pas votre propre jeu, mais seulement celui des autres.",
  rulesGameTitle: "Jouer",
  rulesGame:
    "À son tour, un joueur peut effectuer une (et seulement une) des trois actions suivantes. Les joueurs ne sont pas autorisés à passer.\n\n1. Donner des indices à un autre joueur sur son jeu\n2. Défausser une carte\n3. Jouer une de ses cartes.\n\nLes joueurs ne sont pas autorisés à donner des indices ou des suggestions pendant le tour des autres joueurs.",
  rulesHintTitle: "1. Donner des indices",
  rulesHint:
    "Donner un indice consomme un jeton bleu. Ainsi, si vous n'avez plus de jeton bleu, vous ne pouvez pas donner d'informations et vous devez choisir une autre des deux actions possibles.\nVous pouvez donner un indice soit sur la valeur, soit sur les couleurs du jeu d'un joueur.\nPar exemple, en cliquant sur la vignette bleue, le joueur recevra l'indice : tes cartes A et B sont bleues.",
  rulesDiscardTitle: "2. Défausser une carte",
  rulesDiscard:
    "Défausser une carte permet de regagner un jeton bleu. Pour défausser, cliquez sur votre jeu puis sélectionnez une de vos cartes et cliquez sur défausser. Vous allez alors repiocher une carte qui sera ajoutée, par la gauche, à votre main.\n\nNote : si vous êtes au maximum de 8 jetons bleus, vous ne pouvez pas défausser et il vous faudra choisir une autre action.\nVous pouvez consulter les cartes de la défausse dans la section sur le plateau.",
  rulesPlayTitle: "3. Jouer une carte",
  rulesPlay:
    "À votre tour, pour jouer une carte, sélectionnez une carte de votre jeu et cliquez sur Jouer.\nDeux possibilités\n- la carte démarre ou complète une pile : elle sera ajoutée à la pile du feu d'artifice correspondant\n- la carte ne complète aucune pile : elle sera ajoutée à la défausse et vous récupérez un jeton rouge de pénalité\nDans les deux cas, vous repiochez une carte.\nVous n'avez pas à désigner une pile de couleur pour jouer une carte : vous la jouez sur l'ensemble des piles.\nQuand un joueur termine un feu d'artifice en déposant le 5 de la pile, les joueurs récupèrent un jeton bleu bonus. Si vous êtes déjà au maximum, vous ne récupérez pas de jeton bleu.",
  rulesEndTitle: "Fin du jeu",
  rulesEnd:
    "La partie peut se terminer dans un des trois cas :\n- Au troisième jeton de pénalité, vous perdez la partie et vos poudres s'enflamment\n- Si votre équipe a terminé toutes les piles jusqu'à la valeur 5, votre équipe a réalisé un magnifique feu d'artifice et votre score est de 25 points. Le spectacle est grandiose.\n- Lorsqu'un joueur récupère la dernière carte de la pioche, le jeu est presque terminé. Un dernier tour de jeu est encore possible et chaque joueur peut effectuer une dernière action, en incluant le joueur qui a pioché la dernière carte. S'ils jouent ou défaussent, les joueurs ne récupèrent pas de nouvelles cartes.\nSi les piles n'ont pas été complétées score final atteint est donc la somme des valeurs maximales de chaque pile.",
  rulesExtensionTitle: "Extension",
  rulesExtensionMulticolor:
    "🎨 L'extension multicolore rajoute une sixième pile et un sixième set de cartes multicolores avec un seul exemplaire de chaque valeur : 1, 2, 3, 4, 5. Ces feux d'artifice sont donc plus rares et il faudra prendre garde à ne pas les défausser. Les règles sont les mêmes et vous devez donc compléter les six piles pour un score maximum de 30 points.",
  rulesExtensionRainbow:
    "🌈 L'option arc-en-ciel elle aussi rajoute une sixième pile de cartes multicolores des mêmes exemplaires que les 5 autres piles ( trois cartes de valeur 1, 2 exemplaires des 2 3 et 4, un seul 5). La particularité est que les cartes arc-en-ciel sont de toutes les couleurs. Elles sont touchées par tous les indices de couleur.",
  rulesUsTitle: "Qui sommes-nous ?",
  rulesUs:
    "Nous sommes un groupe d'amateurs de jeux de société. Pendant le confinement en 2020, nous cherchions une alternative pour jouer ensemble en ligne. Et comme on apprécie non seulement de jouer mais également de développer des sites, nous avons créé une version en ligne accessible sur téléphone du jeu.\n\n\n\n👉 Comme nous, achetez la <2>version physique</2> pour soutenir son créateur si vous aimez le jeu!",
  rulesBuy:
    "N'hésitez pas à <1>nous contacter</1> si vous avez une question ou suggestion !\nNous avons aussi un <3>repo public sur Github</3> si vous souhaitez contribuer au site.",
  noRoom: "Aucune partie n'est accessible",
  faster: "Très rapide",
  fast: "Rapide",
  slow: "Lent",
  discard: "Défausser",
  play: "Jouer",
  green: "verte",
  red: "rouge",
  yellow: "jaune",
  blue: "bleue",
  white: "blanche",
  multicolor: "multicolore",
  orange: "orange",
  green_plural: "vertes",
  red_plural: "rouges",
  yellow_plural: "jaunes",
  blue_plural: "bleues",
  white_plural: "blanches",
  multicolor_plural: "multicolores",
  orange_plural: "oranges",
  summary: "Résumé",
  summarySubtitle: "Notre partie d'Hanabi",
  gameCompleted: "Partie terminée en {{ gameDuration }}",
  result: "Notre résultat",
  evolution: "Évolution",
  evolutionSubtext:
    "Suivez le déroulement du jeu ! La carte de chaque joueur est affichée, qu'elle soit jouable, défaussable ou dangereuse (une carte qui fera baisser votre score maximum possible si vous la défaussez, par exemple un multicolore ou un 5).",
  playerActions: "Actions moyennes par joueur",
  playHints: "Nombre moyen de cartes jouées par indice : <1>{{playsPerHint}}</1>",
  playerHints: "Qui a donné le plus d'indices ?",
  tryOutTitle: "Relève le défi",
  tryOutButton: "Essayez cette série",
  tryOutAlternative:
    "Tu peux également jouer avec d'autres configurations et rencontrer notre IA sur <1>hanabi.cards</1>",
  back: "Retour",
  statsPlayable: "jouable",
  statsDiscardable: "défaussable",
  statsDangerous: "dangereuse",
  statsTurnCard: "Ce tour-là, la carte était",
  statsTurnAction: "Action du joueur à ce tour",
  showInsights: "Voir les informations",
  showCards: "Voir les cartes",
  started: "commencé",
  youGaveHintTurn: "Tu as donné un indice à <1></1> sur ses <3></3>",
  youDiscardedTurn: "Tu as défaussé ton <1></1>",
  youPlayedTurn: "Tu as joué ton <1></1>",
  somebodyHintedYouTurn: "<0>PlayerName</0> t'a montré tes <2></2>",
  somebodyHintedSomebodyTurn: "<0>PlayerName</0> a montré à <2></2> ses <4></4>",
  somebodyDiscardedTurn: "<0>PlayerName</0> a défaussé son <2></2>",
  somebodyPlayedTurn: "<0>PlayerName</0> a joué son <2></2>",
  whatYouDrewTurn: "et as pioché le <1></1>",
  whatTheyDrewTurn: "et a pioché le <1></1>",
  selectLanguage: "Choisis une langue",
  creatingGame: "Partie en cours de création...",
  partySetup: "Joueurs : {{players}} · Variante : {{variant}} · Série #{{shuffle}}",
  sendMessagePlaceholder: "Envoie un message à tes partenaires",
  sendMessage: "Envoyer",
  message: "<0></0> : <1></1>",
  contributeLanguage: "Contribuer !",
};
