/* eslint-disable @typescript-eslint/camelcase */

export const ru = {
  hanabi: "Ханаби",
  welcome: "Добро пожаловать",
  newGame: "Новая игра",
  tagline: "Играйте в Ханаби онлайн с друзьями!",
  subTagline: "Кооперативная многопользовательская карточная игра доступная на телефоне, планшете и компьютере",
  createRoom: "Создать комнату",
  joinRoom: "Присоедениться к комнате",
  rejoinGame: "Вернуться в игру",
  whatsHanabi: "Что такое Ханаби ?",
  invitationByPlayers: "{{playersNames}} приглашают Вас сыграть в Ханаби 🎉",
  invitationByPlayer: "{{playerName}} приглашет Вас сыграть в Ханаби 🎉", // necessary in French
  invitationNoPlayers: "Вас приглашают сыграть в Ханаби 🎉",
  gameFull: "Все в сборе!",
  gameNotFull: "{{joined}} / {{playersCount}} уже присоеденился",
  gameNotFullPlural: "{{joined}} / {{playersCount}} уже присоединились", // necessary in French
  startGame: "Начать игру",
  addAi: "+ добавить ИИ",
  lobby: "Лобби",
  choosePlayerName: "Выберите ваше имя игрока",
  join: "Присоедениться",
  autoplay: "Авто-игра",
  shareGame: "Поделиться этой игрой",
  copy: "Копировать",
  waitForOthers: "Ждать остальных или ",
  gameStarted: "Игра началась!",
  gameStarts: "Игра начинается!",
  discardPile: "сброс ({{ discardLength }})",
  preventLossContent: "Ты облажался · Продолжить?",
  rewind: "🕑 Отмотать",
  backToGame: "Назад к игре",
  theirTurn: "Сейчас  ход {{currentPlayerName}}!",
  go: "Вперед!",
  estimatedMaxScore: "Предполагаемый максимальный счет для этой раздачи: {{reachableScore}}.",
  keepPracticing: "Продолжайте тренироваться",
  congrats: "Вы отлично справились!",
  hideStats: "Спрятать статистику",
  showStats: "Показать статистику",
  gameOver: "Игра окончена! • Ваш счет {{playedCardsLength}} 🎉",
  score: "Ханаби • Счет: {{score}} / {{maxPossibleScore}}",
  turnLeftDisclaimer: "· 1 ход остался",
  turnsLeftDisclaimer: "· {{actionsLeft}} ходов осталось",
  cardLeft: "{{pileLength}} осталось",
  tokens: "жетоны",
  deck: "колода",
  loading: "Загрузка...",
  menu: "Меню",
  tutorial: "Обучение",
  rules: "Правила",
  yourTurn: "Ваш ход",
  youWillStart: "Вы ходите первым",
  hide: "Спрятать",
  reveal: "Показать",
  cardSelected: "Выбрана карта {{position}}",
  eightTokens: "8 жетонов",
  cannotDiscard: "Вы не можете сбросить",
  noTokens: "Не осталось жетонов для подсказок",
  selectVignette: "Выберите цвет или цифру для подсказки",
  hint: "Подсказка",
  negativeHintColor: "У вас нет карт с цветом: {{ color }}",
  negativeHintNumber: "У вас нет {{ number }}",
  positiveHintColor: "Цвет карты {{ position }} - {{ color }}",
  positiveHintNumber: "На карте {{ position }} цифра {{ number }}",
  positiveHintNumberSequence: "Цифра на карте {{ position }} - {{ number }} или выше",
  positiveHintColorPlural: "Цвет карт {{ positions }} - {{ color }}",
  positiveHintNumberPlural: "На картах {{ positions }} цифра {{ number }}",
  positiveHintNumberSequencePlural: "На картах {{ positions }} цифра {{ number }} или выше",
  you: "Вы",
  hinted: "Подсказал",
  played: "Сыграл",
  discarded: "Сбросил",
  rollback: "Откатить",
  rollbackDisclaimer: "Вы собираетесь откатить последнее действие!",
  cannotRollback: "Вы не можете сейчас откатить игру",
  abort: "Отмена",
  introTutorial: "Обучение",
  introContent: "Давайте научимся играть!",
  playedCardsTutorial: "Сыгранные карты",
  playedCardsTutorialContent: "Сыгранные карты соберутся в стопку.\nВместе вам нужно дойти до 5, что бы выиграть игру.",
  discardTutorial: "Сбросить",
  discardTutorialContent:
    "Здесь вы увидите карты\nкоторые сбросила ваша команда.\nИзбегайте сброса тех карт, которые нужны для завершения игры.",
  yourGameTutorial: "Ваша игра",
  yourGameTutorialContent: "Это Ваши карты.\nВы их не видите, но их видят другие игроки и дают Вам о них подсказки.",
  teammatesTutorial: "Партнеры",
  teammatesTutorialContent:
    "Вот ваши партнеры.\nКак и Вы, они не видят свои карты.\nДавайте им подсказки, что бы они смогли сыграть или сбросить свои карты.",
  hintTokensTutorial: "Жетоны подсказок",
  hintTokensTutorialContent:
    "Некоторые действия имеют цену.\n\n- Дать подзказку стоит один жетон подсказки.\n- Сбрасывая карту Вы получаете один жетон подсказки.\n- Играя карту с цифрой 5 вы получаете один бонусный жетон подсказки.",
  strikeTokensTutorial: "Жетоны ошибок",
  strikeTokensTutorialContent:
    "Сыграв неподходящую карту вы сбросите ее и получите один жетон ошибки.\nДостигнув 3 жетонов ошибки вы моментально проиграете.",
  yourTurnTutorial: "Ваш ход",
  yourTurnTutorialContent:
    "У вас есть 3 варианта:\n\n- Нажмите на  себя чтобы сыграть карту...\n- ... или сбросить её\n- Нажмите на своего партнёра чтобы дать ему подсказку.",
  skip: "✕ Пропустить",
  offline: "Вы не в сети !",
  apologies: "Мы сожалеем, что-то пошло не так 😕",
  errorWillInvestigate:
    "Мы выясним всё как можно быстрее. Не стесняйтесь <1>писать нам</1>, чтобы узнать что произошло.",
  earlyDevelopment: "Игра все еще в ранней стадии разработки и мы очень ценим обратную связь.",
  publicRepo:
    "Также у нас есть <1>публичный репозиторий Github</1>, на случай если вы захотите помочь или сообщить об ошибке.",
  backMenu: "Назад в Меню",
  landingImageAlt: "Лого карточной игры Ханаби онлайн",
  classicVariant: "Классический",
  orangeVariant: "Оранжевый",
  multicolorVariant: "Разноцветный",
  rainbowVariant: "Радужный",
  sequenceVariant: "Последовательный",
  classicVariantDescription: "Классическая игра Ханаби с 5 цветами",
  multicolorVariantDescription: "6-ой набор с одной копией каждой карты",
  rainbowVariantDescription: "6-ой набор, где карты имеют все цвета",
  orangeVariantDescription: "Добавлен обычный 6-ой набор",
  sequenceVariantDescription: "Подсказки о цифре говорят и о картах с цифрой выше",
  showDirectHints: "Показывать прямые подсказки",
  hideDirectHints: "Не показывать прямые подсказки",
  botSpeed: "Скорость ботов",
  normalGameExplanation: "Вы сможете играть по интернету поделившись ссылкой со своими друзьями.",
  passandplayExplanation:
    "В режиме передай-и-играй вы можете играть оффлайн, когда несколько игроков находятся рядом, передавая устройство нужному игроку в его ход",
  private: "Приватная",
  hints: "Подсказки",
  allowRollback: "Позволить откатывать игру",
  preventLoss: "Предотвращать поражение",
  passandplay: "Передай и играй",
  passandplaySubtext: "Передавайте устройство нужному игроку в его ход",
  privateSubtext: "Вашу игру не будет видно в разделе 'Присоедениться к комнате'",
  advancedOptions: "Больше опций",
  players: "Игроки",
  mode: "Режим",
  seed: "Зерно",
  objective: "Цель",
  rulesIntro:
    "Ханаби это карточная игра созданная Антуаном Боза. Она кооперативная, а значит игроки играют не друг против друга, а объединены общей целью. Они предстают в роли пиротехников, которые по невнимательности смешали свой порох, фитили и заряды, готовясь к большому шоу фейерверков. Шоу скоро начнётся, а вокруг происходит хаос. Теперь они должны помочь друг-другу что бы не дать превратиться шоу в сплошное разочарование.",
  rulesGoal:
    "Цель команды пиротехников - собрать 5 фейерверков, по одному каждого цвета (белый, красный, синий, желтый, зеленый) разыгрывая карты одного цвета по возрастающей (1,2,3,4,5).",
  rulesSetupTitle: "Подготовка",
  rulesSetup:
    "Это чудесное приложение сделает всю подготовку к игре за Вас 😉. В начале игры у Вас будет 8 синих жетонов (жетонов подсказок) и 3 красных жетона - это жетоны наказания.\n\nКолода состоит из 50 карт, по 10 каждого цвета <1> с цифрами 1, 1, 1, 2, 2, 3, 3, 4, 4, 5</1>.\n· Если игроков 2 или 3, то каждому игроку раздается по 5 карт\n· Если игроков 4 или 5, то каждому игроку раздается по 4 карты.\n\nИгроки не могут смотреть на свои карты!",
  rulesGameTitle: "Ход игры",
  rulesGame:
    "Каждый игрок в свой ход выполняет ровно одно из трех следующих действий. Пропустить ход нельзя.\n\n1. Дать информацию другому игроку.\n2. Сбросить карту.\n3. Сыграть карту.\n\n Игроки не могут делиться подсказками в ход другого игрока.",
  rulesHintTitle: "1. Дать информацию.",
  rulesHint:
    "На то, что бы поделиться информацией тратится синий жетон. Если у Вас не осталось синих жетонов, то Вы не можете делиться информацией и должны выбрать другое действие.\n\nЗатем Вы можете поделиться информацией с другим игроком о картах в его руке кликнув на нее. Вы можете дать информацию либо об одном цвете, либо об одном значении карт.",
  rulesDiscardTitle: "2. Сбросить карту",
  rulesDiscard:
    "Сбрасывая карту Вы получаете синий жетон назад. Сбросить карту из руки можно нажав на нее. После этого Вы вытяните новую карту из колоды. \nПримечание: Если у Вас есть все 8 синих жетонов, Вы не можете сбросить карту и должны выбрать другое действие. \nВы можете смотреть в сброшенные карты в любое время.",
  rulesPlayTitle: "3. Сыграть карту",
  rulesPlay:
    "В Ваш ход Вы можете разыграть карту из руки.\nДалее происходит одно из двух:\n· Если карта начинает или продолжает фейерверк, она будет добавлена в стопку этого фейерверка\n· Если карта не подходит к фейерверку, она будет сброшена и вам добавится жетон ошибки\nПосле этого Вы вытяните новую карту из колоды.\n\nКогда игрок заканчивает фейерверк разыгрывая карту с цифрой 5 на ней, команда получает один бонусный синий жетон. Если все синие жетоны уже у вас, то команда бонус не получит.",
  rulesEndTitle: "Конец игры",
  rulesEnd:
    "Существует 3 варианта окончания игры в Ханаби:\n· Если вы собираете три красных жетона, то игра тут же заканчивается ужасным взрывом!\n· Если вы полностью собираете все фейерверки, то игра заканчивается невероятным представлением и команда набирает максимальное количество очков!\n· Если игрок вытягивает последнюю карту из колоды, то игра вот-вот закончится. Каждый игрок совершает еще по одному ходу. В течении этих ходов игроки больше не берут карты.\n\nПотом подсчитываются очки, основываясь на том, какие фейерверки получились у игроков.",
  rulesExtensionTitle: "Дополнения",
  rulesExtensionMulticolor:
    "🎨 Разноцветный вариант добавляет 6-ой тип карт, в котором есть по одной карте каждого значения: 1, 2, 3, 4, 5. Эти фейерверки более редкие и вам стоит обращаться с ними более аккуратно. Все остальные правила те же. Вам нужно собрать 6 фейерверков, чтобы добиться максимального счета: 30 очков.",
  rulesExtensionRainbow:
    "🌈 Радужное дополнение также добавляет 6-ой тип карт с теми же значениями что и у основных цветов (три 1, по две 2/3/4 и одна 5). Радужные карты собираются в отдельный фейерверк. Особенность радужных карт в том, что они всех цветов сразу. Каждый раз когда вы даете подсказку о цвете это затронет и их.",
  rulesUsTitle: "Кто мы?",
  rulesUs:
    "Мы группа любителей настольных игр. Во время самоизоляции в 2020 мы хотели найти способ играть удаленно.\nТак как программирование и игра в Ханаби доставляют нам удовольствие, мы решили создать онлайн версию игры, которая бы работала на мобильных девайсах.\n\n👉 Если вам нравится игра, приобретите <2>физическию версию</2> чтобы поддержать ее создателя!",
  rulesBuy:
    "Не стесняйтесь <1>писать нам</1> по поводу ваших вопросов и предложений. У нас также есть <3>публичный репозиторий Github</3> на случай если вы хотите нам помочь.",
  noRoom: "Нет свободного места",
  faster: "Быстрее",
  fast: "Быстро",
  slow: "Медленно",
  discard: "Сбросить",
  play: "Сыграть",
  green: "зеленая",
  red: "красный",
  yellow: "желтый",
  blue: "синий",
  white: "белый",
  multicolor: "разноцветный",
  orange: "оранжевый",
  green_plural: "зеленые",
  red_plural: "красные",
  yellow_plural: "желтые",
  blue_plural: "синие",
  white_plural: "белые",
  multicolor_plural: "разноцветные",
  orange_plural: "оранжевые",
  summary: "Отчет",
  summarySubtitle: "Наша игра в Ханаби",
  gameCompleted: "Игра закончилась за {{ gameDuration }}",
  result: "Наш результат",
  evolution: "История",
  evolutionSubtext:
    "Следи за историей игры! Каждая карта помечена: можно ли было ее сыграть, сбросить или она являлась опасной (она понизила бы максимальный возможный счет если бы вы ее сбросили).",
  playerActions: "Среднее количество действий на игрока",
  playHints: "Среднее количество сыгранных карт на одну подсказку: <1>{{playsPerHint}}</1>",
  playerHints: "Кто дал больше всего подсказок?",
  tryOutTitle: "Попробуйте!",
  tryOutButton: "Попробовать эту раздачу",
  tryOutAlternative: "Вы также можете сыграть с другими настройками или взять в команду наш ИИ на <1>hanabi.cards</1>",
  back: "Назад",
  statsPlayable: "можно сыграть",
  statsDiscardable: "можно сбросить",
  statsDangerous: "опасная",
  statsTurnCard: "Карта в этот ход",
  statsTurnAction: "Действие игрока в этот ход",
  showInsights: "Показать информацию",
  showCards: "Показать карты",
  started: "начало",
  youGaveHintTurn: "Вы подсказали <1></1> о <3></3>",
  youDiscardedTurn: "Вы сбросили <1></1>",
  youPlayedTurn: "Вы сыграли <1></1>",
  somebodyHintedYouTurn: "<0></0> подсказал Вам о <2></2>",
  somebodyHintedSomebodyTurn: "<0></0> подсказал <2></2> о <4></4>",
  somebodyDiscardedTurn: "<0></0> сбросил <2></2>",
  somebodyPlayedTurn: "<0></0> сыграл <2></2>",
  whatYouDrewTurn: "и вытянул <1></1>",
  whatTheyDrewTurn: "и вытянул <1></1>",
  selectLanguage: "Выбрать язык",
  creatingGame: "Создаём игру...",
  partySetup: "Игроки: {{players}} · Режим: {{variant}} · Раздача #{{shuffle}}",
  sendMessagePlaceholder: "Отправить сообщение Вашей команде",
  sendMessage: "Отправить",
  message: "<0></0>: <1></1>",
  contributeLanguage: "способствовать!",
};
